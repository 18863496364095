.adminSection{
    margin: 20px;
    padding: 15px;
    background: #fff;
    border-radius: 4px;

}

.sectionHead{
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */

    padding-block: 9px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.sectionBody{
    /* display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap; */
    text-align: left;
    margin-top: 20px;
}

.sectionHead h3{
    margin-bottom: 0;
}